module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"errezeta","short_name":"errezeta","start_url":"/","background_color":"#FF2600","theme_color":"#FF2600","display":"standalone","icon":"src/images/errezeta-icon.png","include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"64718ac0ddabfa3dee79fff2281f073b"},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"testtesttest"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
