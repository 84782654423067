import * as React from "react"
//https://www.smooth-code.com/open-source/svgr/playground/

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 450 463"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
      {...props}
    >
      <circle
        cx={258.2}
        cy={256.9}
        r={246.6}
        fill="#ff2600"
        transform="matrix(.9125 0 0 .93786 -10.6 -9.7)"
      />
      <circle
        cx={258.2}
        cy={256.9}
        r={246.6}
        fill="#fff"
        stroke="#fff"
        strokeWidth={8.2}
        transform="matrix(.81229 0 0 .83487 15.3 16.7)"
      />
      <path
        d="M103.4 392.3l4-70.5A91.6 91.6 0 0195 317c-11-5.6-24.6-12-28.3-25-6-21.1-2-49-.1-69.5 2.9-30.4 6.3-60.8 9.7-91.1H89v142.4h11V131.3h14.3v142.4H127V131.3h14v142.4h11.1V131.3H165c3.4 30.3 6.8 60.7 9.7 91 2 20.6 6 48.5 0 69.5-3.8 13.2-17.5 19.5-28.4 25.1-4 2-8.3 3.5-12.5 5l4.9 95-35.3-24.6zm246.9-4l-29 22.8 3.2-100.8-8.7-3.3s-15.2-6.7-24.6-22.6a85 85 0 01-12.6-35c-1.7-10.5-4.6-23-2.3-40s5.8-33 13-44.5a65.6 65.6 0 0120.2-23.2c7.5-4.7 12.5-9.9 26.7-10h-.1c14 .1 18.8 5.3 26.1 10 7.4 4.7 13 11.6 20 23.2 7 11.5 10.4 27.5 12.7 44.5 2.2 17-.6 29.5-2.4 40a86.2 86.2 0 01-12.3 35C371 300.3 356 307 356 307l-8.6 3.3 2.8 78zm-138 46.7l5.9-115.2-4.4-8.4s-3.8-18.8-5.7-30.3c-1.9-11.5-6-55.7-5.1-80 .8-24.5 5.5-81.5 8.6-89 3.1-7.4 7.4-20 10.5-25.7 3-5.7 5.3-13.4 11.2-15.6 6-2.2 8.8-1.7 11.5-.4 2.6 1.2 4 3.7 4.4 6.2.5 2.5 5.5 209 5.2 215.5-.4 6.5-10.6 28.2-10.6 28.2l7.5 114.6h-39z"
        fill="#ff2600"
      />
    </svg>
  )
}

export default SvgComponent
