/**
 * Write-only the password as cookie
 */
import React, { useState } from "react"
import Cookies from "js-cookie"

import SVGLogo from "../../../components/svg/logo"

const COOKIE_NAME = "gatsby-theme-password-protect"

export const setSessionPassword = passwordCandidate => {
  Cookies.set(COOKIE_NAME, passwordCandidate)
}

const PasswordProtect = () => {
  const [password, setPassword] = useState("")

  const onSubmit = event => {
    event.preventDefault()
    setSessionPassword(password)
    window.location.reload() // eslint-disable-line
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="e_food-background vh-100 d-flex justify-content-center align-items-center">
        <div className="text-center text-white">
          <SVGLogo />
          <h1 className="pb-5">errezeta</h1>

          <input
            className="form-control form-control-lg my-3 text-center"
            name="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <button
            className="btn btn-success btn-lg btn-block w-75 mx-auto"
            type="submit"
          >
            enter
          </button>
        </div>
      </div>
    </form>
  )
}

export default PasswordProtect
